<template>
  <v-dialog v-model="showDialog" persistent max-width="470">
    <v-card>
      <v-card-title>Delivery Status:</v-card-title>
      <v-card-text>
        <v-list>
          <v-list-item v-if="delivery.delivery_status">
            <v-list-item-title>Status:</v-list-item-title>
            <v-list-item-action>{{
              delivery.delivery_status
            }}</v-list-item-action>
          </v-list-item>

          <v-list-item>
            <v-list-item-title>
              <a :href="track_url" target="_blank">Track Order</a>
            </v-list-item-title>
          </v-list-item>
        </v-list>

        <v-row>
          <v-card v-if="delivery.courier" class="mx-auto" max-width="400">
            <v-card-text>Driver Info</v-card-text>
            <v-card-title>Name: {{ delivery.courier.name }}</v-card-title>
            <v-card-actions>
              <v-list-item class="grow">
                <v-list-item-avatar color="grey darken-3">
                  <v-img
                    class="elevation-6"
                    :src="delivery.courier.img_href"
                  ></v-img>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title
                    v-if="delivery.delivery_status != 'delivered'"
                  >
                    <a :href="'tel:' + delivery.courier.phone_number">{{
                      delivery.courier.phone_number
                    }}</a>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-card-actions>
          </v-card>
        </v-row>

        <v-row>
          <v-col cols="6" sm="4" lg="3">
            <v-btn
              small
              color="info"
              if="canResubmit"
              @click.stop="onResubmitDelivery"
              >Resubmit Delivery</v-btn
            >
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="3">
            <v-btn
              small
              class="mx-3"
              color="error"
              v-if="!delivery.error"
              @click.stop="onCancelDelivery"
              >Cancel Delivery</v-btn
            >
          </v-col>
          <v-col cols="4">
            <v-alert
              v-if="delivery.error"
              class="small"
              type="error"
              outlined
              >{{ delivery.error }}</v-alert
            >
          </v-col>
        </v-row>
        <!-- <v-list-item>
            <v-list-item-title>{{ $t('Common.service_see') }}</v-list-item-title>
            <v-list-item-action>{{ all_fees.service_fee | currency }}</v-list-item-action>
          </v-list-item>

          <v-list-item v-if="all_fees.discount > 0">
            <v-list-item-title>Discount</v-list-item-title>
            <v-list-item-action>-{{ all_fees.discount | currency }}</v-list-item-action>
          </v-list-item>
          <v-list-item>
            <v-list-item-title>{{ $t('Common.total') }}</v-list-item-title>
            <v-list-item-action>{{ all_fees.total | currency }}</v-list-item-action>
          </v-list-item>
        
        <div class="pa-2 grey lighten-2">
          <h4>{{ $t('Common.authorization_title') }}</h4>
          <p>{{ $t('Common.authorization_sub') }}</p>
        </div>-->
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text depressed color="primary" @click.stop="showDialog = false"
          >Ok</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ["delivery", "track_url"],
  data() {
    return {
      showDialog: false,
    };
  },
  methods: {
    openDialog() {
      this.showDialog = true;
    },
  },
};
</script>
